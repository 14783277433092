import React from 'react'
import {industryData} from './data.js'

export default function Industry(props) {
    return (
        <div className="industry-item col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
            <div className="industry-item-container">
                <div className="industry-item-title-bar text-left padding-left-20">
                    {industryData[props.name].title}
                </div>
                <div className="industry-item-image">
                    <img src={industryData[props.name].imgSrc} width="100%" height="auto" alt="industry-example"/>
                </div>
                <div className="industry-item-text-container">
                    <p className="industry-item-text mb-0 pb-0">
                        {industryData[props.name].text}
                    </p>
                </div>
                <br style={{clear: 'both'}} />
            </div>
        </div>
    )
}
