export const navItemsData = {
    "Home": {
        "text": "HOME",
        "targetScrollClass": "headerImage",
        "navClass": "nav-home",
        "divId": "homeNavBox"
    },
    "Industries": {
        "text": "INDUSTRIES",
        "targetScrollClass": "section-industries",
        "navClass": "nav-industries",
        "divId": "industriesNavBox"
    },
    "About": {
        "text": "ABOUT",
        "targetScrollClass": "section-about-us",
        "navClass": "nav-about",
        "divId": "aboutNavBox"
    },
    "Iso": {
        "text": "ISO",
        "targetScrollClass": "section-iso-certification",
        "navClass": "nav-iso",
        "divId": "isoNavBox"
    },
    "Facilities": {
        "text": "FACILITIES",
        "targetScrollClass": "section-facilities",
        "navClass": "nav-facilities",
        "divId": "facilitiesNavBox"
    },
    "Contact": {
        "text": "CONTACT",
        "targetScrollClass": "section-contact-us",
        "navClass": "nav-contact",
        "divId": "contactNavBox"
    }
}