import React from 'react'
import GoogleMap from './GoogleMap'
import ContactUsAddress from './ContactUsAddress'

export default function ContactUsContent() {
    return (
        <div className="container clear-fix">
            <div className="row sectionPadding">
                <div className="col-lg-6">
                    <div className="row">
                        <ContactUsAddress />
                    </div>
                    
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <h4 className="form-title">Location</h4>
                        <GoogleMap />
                    </div>
                </div>
            </div>
        </div>
    )
}
