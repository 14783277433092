export const aboutUsData = {
    "first-text" : {
        "title": "",
        "content": "Since 1984, Darko Precision has maintained a reputation for delivering the highest quality products within the timeframes promised. We continuously invest in the most efficient manufacturing tools to produce perfectly created close-tolerance, critical parts. Our constant dedication to quality process improvement has made Darko Precision, Inc. an industry leader and a benchmark against which other shops compare themselves. We accept every challenge on all jobs, regardless of scope, size or specifications."
    },
    "second-text" : {
        "title": "Vertical Integration — the Darko difference.",
        "content": "From a project’s inception – whether concept, sketch or blueprint – through each stage all the way through volume manufacturing, quality testing and delivery, our customers know their projects benefit from continuous monitoring, scrutiny and certified traceability. We employ top experts in fields as diverse as design, blueprint analysis, packaging cosmetics, material handling and transport. Our team members’ skills and professional pride reflect the environment and culture at Darko Precision, a culture committed to integrity and quality throughout the company."
    },
    "image-left": {
        "src": "./images/about/about-us-image-1.jpg"
    },
    "image-right": {
        "src": "./images/about/about-us-image-2.jpg"
    }
}