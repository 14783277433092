export const isoData = {
    "image1" : {
        "src": "./images/iso/iso-image.jpg"
    },
    "image2": {
        "src": "./images/iso/IS0-13485.jpg"
    },
    "image3": {
        "src": "./images/iso/ISO-AS9100D.jpg"
    }
}