import React from 'react'
import FacilitiesImages from './FacilitiesImages'

export default function FacilitiesContent() {
    return (
        <div className="container text-center sectionPadding clear-fix">
            <div className="row">
                <div className="col-md-12 regular-text">
                    With a constant focus on efficiency, we continually invest in the most effective manufacturing processes available for close-tolerance parts. Located in the heart of Silicon Valley, our spacious headquarters are designed to allow for production expansion through the implementation of strategic process flow layouts. Our facility boasts state-of-the-art technology and machinery in every stage of the precision machining process. All of this allows Darko Precision to offer unparalleled production capacity at lower costs. We proudly hold four ISO certifications and yet continue an ambitious process improvement program to assure we keep up with growth.<br style={{clear:"both"}} /><br/>Welcome to the Darko difference.
                </div>
            </div>
            <FacilitiesImages />
        </div>
    )
}
