import React from 'react'
import {navItemsData} from './NavItemsData'
import $ from 'jquery';

export default function MobileNavItems() {
    var navObject = {};
    var navHeight;

    function scrollToSection(key) {
        if(!$('.' + key).hasClass('navActive')) {
            $([document.documentElement, document.body]).animate({
                scrollTop: ($("."+navObject[key]).offset().top - navHeight)
            }, 1500);
        }
    }

    function setNavHeight() {
        if(window.innerWidth < 992) {
            navHeight = 110;
        } else {
            navHeight = 196;
        }
    }

    $(function() {

        //alert("your window width is: " + window.innerWidth);


        function fillNavObject() {
            for(let i in navItemsData) {
                navObject[navItemsData[i].navClass] = navItemsData[i].targetScrollClass;
            }
        }

        
        setNavHeight()
        fillNavObject();

        $('.nav-items').click(function(e) {
            e.preventDefault();

            for(let key of Object.keys(navObject)) {
                if(this.classList.contains( key )) {
                    scrollToSection(key);
                }
            }
        });

        $(window).on("resize", function() {
            setNavHeight();
        });
    });

    return (
            <>
                <div className="myLinks">
                    {
                        Object.keys(navItemsData).map((key, i) => {
                            return (
                                <a className={'nav-items ' + navItemsData[key].navClass} href='#' key={key}>
                                    {navItemsData[key].text}
                                </a>
                            )
                        })
                    }
                </div>
            </>
        )
}
