import React from 'react';
import { Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './pages/Home';
import TermsAndConditions from './pages/TermsAndConditions';
import './App.css'
import './responsive.css';

function App() {
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
        </Switch>
      </Route>
    </React.Fragment>
  );
}

export default App;
