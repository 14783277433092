import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

class GoogleMap extends Component {
    render() {
        const style = {
            position: 'relative',
            width: '600px',
            height: '400px',
            float: 'left'
            }
        return (
            <div id="map">
                <Map 
                    google={this.props.google} 
                    zoom={10}
                    initialCenter={{
                    lat: 37.378630,
                    lng: -121.941900
                    }}
                    style={style}
                    >
                    <Marker
                        lat={37.378630}
                        lng={-121.941900}
                        text="Darko Precision, inc."
                    />
                </Map>
                
           </div>
        );
    }
}
export default GoogleApiWrapper({
 apiKey: ('AIzaSyAep8Pmj2MDK-0V4z3eU-zXm1_JZ7Htq9U')
})(GoogleMap);