import React from 'react'

export default function HeaderImage() {
    const img = "images/header/headerImage.jpg";

    return (
        <div id="headerImage" className="headerImage">
        </div>
    )
}
