import React from 'react'
import {headerImgData} from './HeaderImageData.js'

export default function SectionHeaderImage(props) {
    return (
        <>
            <div id={headerImgData[props.sectionName].id}></div>
            <div className="header-title-box-container">
                <div className="header-title-box">
                    <div className="title-over-header-image">{headerImgData[props.sectionName].title}</div>
                </div>
            </div>
        </>
    )
}
