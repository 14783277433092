import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'

export default function DarkoAddress() {
    return (
        <div className="contactAddressText">
            Darko Precision, Inc.<br/>470 Gianni Street<br/>Santa Clara, CA 95054<br/><FontAwesomeIcon icon={faPhone} /> 408.988.6133<br/><FontAwesomeIcon icon={faEnvelope} /> info@dp-inc.com
        </div>
    )
}
