import React from 'react'
import {aboutUsData} from './AboutUsData'

export default function AboutUsContent() {
   return (
        <div className="container padding-top-120 sectionPadding clear-fix">
            <div className="regular-text">{aboutUsData["first-text"].content}</div>
            <h3 className="sub-title-small-left">{aboutUsData["second-text"].title}</h3>
            <div className="regular-text">{aboutUsData["second-text"].content}</div>
            <div className="regular-text margin-bottom-50 margin-top-20"><a href="/QSP-PU-01_Purchase_Order_Work_Instruction_Rev07.pdf">Terms and conditions</a></div>
            <div className="row aboutImages">
                <div className="col-xs-12 col-sm-12 col-md-4 aboutUsImages">
                    <img src={aboutUsData["image-left"].src} alt="background" width="100%" height="auto" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 aboutUsImages">
                    <img src={aboutUsData["image-right"].src} alt="background" width="100%" height="auto" />
                </div>
            </div>
        </div>
    )
}
