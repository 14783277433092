import React from 'react'
import NavItems from './NavItems'
import MobileNavItems from './MobileNavItems'
import {navItemsData} from './NavItemsData'
import Logo from './Logo'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons'

export default function Nav() {
    var navHeight;

    $(function() {
        window.onscroll = function() {myFunction()};
        
        // close mobile menu on loose focus
        $(document).mouseup(function (e) {
            var divContent= $(".mobile-container");
            var topNav = document.getElementsByClassName("topnav")[0];
            if(topNav && topNav.style.display === "block") {
                if(!divContent.is(e.target) && divContent.has(e.target).length === 0) {
                    myFunction3();
                }
            }
        });

        setNavHeight();

        $(window).on("resize", function() {
            setNavHeight();
        });
    });

    function setNavHeight() {
        if(window.innerWidth < 992) {
            navHeight = 110;
        } else {
            navHeight = 196;
        }
    }

    
    
    $(window).on("load", myFunction);


    var positions = {
        "windowWidth": "",
        "items": []
    };
    
    


    function myFunction() {
        var header = document.getElementById("nav-bar");
        var sticky = header.offsetTop;

        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
        
        higlightNavLinks();
    }

    function higlightNavLinks() {
        let keys = Object.keys(navItemsData);
        let windowOffset = window.pageYOffset;

        //console.log(window.pageYOffset);

        if(windowOffset > navHeight) {
            windowOffset += navHeight;
        }

        if(!positions.windowWidth || positions.windowWidth !== window.innerWidth) {
            positions.windowWidth = window.innerWidth;

            positions.items = keys.reduce(function(result, key) {
                if (navItemsData[key].targetScrollClass) {
                    if(key.toLowerCase() === 'home') {
                        result.push({"classPart": navItemsData[key].text.toLowerCase(), "offset": 0});
                    } else {
                        let offset = document.getElementsByClassName(navItemsData[key].targetScrollClass)[0].offsetTop;
                        result.push({"classPart": navItemsData[key].text.toLowerCase(), "offset": offset});
                    }
                }
                return result;
            }, []);
        }

        let max = {
            "num": -1,
            "classPart": ""
        };

        positions.items.forEach(function(el) {
            if( windowOffset >= el.offset && el.offset > max.num) {
                max.num = el.offset;
                max.classPart = el.classPart;
            }
        });

        positions.items.forEach(function(el) {
            if(el.classPart == max.classPart) {
                document.getElementsByClassName('nav-' + max.classPart)[0].classList.add("navActive");
            } else {
                document.getElementsByClassName('nav-' + el.classPart)[0].classList.remove("navActive");
            }
        });
    }

    function myFunction3() {
        var mobileMenu = document.getElementsByClassName("mobile-container")[0];
        var topNav = document.getElementsByClassName("topnav")[0];
        var closeNav = document.getElementsByClassName("closeNav")[0];
        var openNav = document.getElementsByClassName("openNav")[0];
        if(topNav) {
            if (topNav.style.display === "block") {
                topNav.style.display = "none";
                mobileMenu.style.width = "29.5px";
                mobileMenu.style.backgroundColor = "#FFF";
                closeNav.style.display = "none";
                openNav.style.display = "block";
            } else {
                topNav.style.display = "block";
                mobileMenu.style.width = "100%";
                mobileMenu.style.backgroundColor = "#333";
                closeNav.style.display = "block";
                openNav.style.display = "none";
            }
        }
    }

    return (
        
            <nav id="nav-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg-4 col-xl-6 pl-0">
                            <Logo />
                        </div>

                        <div className="navItemsBox col-lg-8 col-xl-6 pl-0">
                            <NavItems />
                        </div>

                        <div className="mobileNavItemsBox col-6 pl-0">
                            <div id="mobileNavContainer" className="mobile-container">
                                <button className="icon" onClick={myFunction3}>
                                    <FontAwesomeIcon className="openNav" icon={faBars} />
                                    <FontAwesomeIcon className="closeNav" icon={faTimes} />
                                </button>
                                <div className="topnav">
                                    <MobileNavItems />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
    )
}
