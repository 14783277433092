import React from 'react'
import {facilityImages} from './FacilitiesData'

export default function FacilitiesImages() {
    return (
            <div className="row text-center facility-images">
                <div className="col-6 col-lg-3 facilitiesImages">
                    <img src={facilityImages.image1.src} alt="images" width="100%" height="auto" />
                </div>
                <div className="col-6 col-lg-3 facilitiesImages">
                    <img src={facilityImages.image2.src} alt="images" width="100%" height="auto"/>
                </div>
                <div className="col-6 col-lg-3 facilitiesImages">
                    <img src={facilityImages.image3.src} alt="images" width="100%" height="auto"/>
                </div>
                <div className="col-6 col-lg-3 facilitiesImages">
                    <img src={facilityImages.image4.src} alt="images" width="100%" height="auto"/>
                </div>
            </div>
    )
}