export const facilitiesData = {
    "firstMainHeader" : "CNC Equipment List",
    "secondMainHeader" : "Manual Equipment List",
    "firstTableHeader" : "Vertical Milling Centers (CNC Vertical Mills)",
    "secondTableHeader": "Horizontal Milling Centers (CNC Horizontal Mills)",
    "thirdTableHeader": "Horizontal Turning Centers (CNC Lathes)",
    "fourthTableHeader": "Manual Mills",
    "fifthTableHeader": "Manual Lathes",
    "sixthTableHeader": "Welding equipment"
};

export const facilityImages = {
    "image1": {
        "src": "./images/facilities/facilities-img-1.jpg"
    },
    "image2": {
        "src": "./images/facilities/facilities-img-2.jpg"
    },
    "image3": {
        "src": "./images/facilities/facilities-img-3.jpg"
    },
    "image4": {
        "src": "./images/facilities/facilities-img-4.jpg"
    }
}