export const industryData = {
    "medical" : {
        "title": "MEDICAL",
        "imgSrc": "./images/industries/medical.jpg",
        "text": "Medical parts designers and manufacturers demand exceptional precision. We deliver on the promise to support our customers working for the benefit of us in a variety of sectors, whether cancer treatment, surgery or injury care."
    },
    "aerospace" : {
        "title": "AEROSPACE",
        "imgSrc": "./images/industries/aerospace.jpg",
        "text": "Perfect machining for the most advanced machines on earth – and above it. High quality and exotic materials come together to meet exacting standards."
    },
    "defense" : {
        "title": "DEFENSE",
        "imgSrc": "./images/industries/defense.jpg",
        "text": "High-grade, reliable parts delivered on time have earned us the respect of the nation’s top defense contractors."
    },
    "semiconductor" : {
        "title": "SEMICONDUCTOR",
        "imgSrc": "./images/industries/semiconductor.jpg",
        "text": "We consistently deliver high-quality components for an ever-changing industry. With global electronics moving at the speed of design, we fulfill on time, every time."
    },
    "biotechnology" : {
        "title": "BIOTECHNOLOGY",
        "imgSrc": "./images/industries/biotechnology.jpg",
        "text": "As biotech companies delve deeper into complex inquiries and require complex tools, our precision output makes possible advances in analysis, diagnostics and instrumentation."
    },
    "energy" : {
        "title": "ENERGY",
        "imgSrc": "./images/industries/energy.jpg",
        "text": "New technologies drive change in the energy sector every day, and our ability to adapt and deliver has companies covered."
    },
    "communications" : {
        "title": "COMMUNICATIONS",
        "imgSrc": "./images/industries/communications.jpg",
        "text": "We machine parts for a variety of housings, enclosures and components in the communications industry, whether standard or hardened applications. As the communications industry advances so too does our ability to keep pace."
    },
    "reasearch-and-science" : {
        "title": "RESEARCH AND SCIENCE",
        "imgSrc": "./images/industries/research.jpg",
        "text": "Darko Precision meets the tight tolerances and rigorous standards in the fields of research and science. Our background and expertise across multitudes of industries and makes us the adaptable and flexible choice for any project."
    }
}