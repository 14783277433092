import React from 'react'
import FooterAddress from './FooterAddress'
import {navItemsData} from '../navigation/NavItemsData'

export default function FooterContent() {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-xs-3 col-md-4 order-2 order-md-1">
                        <FooterAddress />
                    </div>
                    <div className="col-xs-12 col-md-8 order-1 order-md-2">
                        <div className="footerLinks">
                            {
                                Object.keys(navItemsData).map((key, i) => {
                                    return (
                                            <a className={'nav-items footer-nav ' + navItemsData[key].navClass} href='#' key={key}>
                                                {navItemsData[key].text}
                                            </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center footer-copyright">Darko Precision 2020 | All rights reserved. | <a href="/QSP-PU-01_Purchase_Order_Work_Instruction_Rev07.pdf">Terms and conditions</a></div>
                </div>
            </div>
        </div>
    )
}
