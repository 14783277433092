import React from 'react'
import Industry from './Industry.js'

export default function Industries() {
    return (
        <>
            <div className="container">
                <h1 className="main-title main-title-color">Darko Precision. The confident choice in precision-machine products.</h1>
                <div className="regular-text regular-text-color">Today’s contract manufacturing environment is marked by ever-tightening demands. In technological expertise. Tolerances. Process monitoring and quality assurance. Record-development and retention. Delivery. And, of course, costs. More than ever, prudent purchasers seek certainty, certain knowledge that—start to finish—each demand, each need, will be met without compromise. It’s that proven no-compromise approach that makes Darko Precision the first choice of industry leaders.</div>
                <div className="container text-center pl-0 pr-0">
                    <h2 className="industriesTitle subtitle-small-middle">Industries we serve</h2>
                </div>
                <div className="regular-text regular-text-color">We serve industries and companies on the cutting edge of technology, continuously meeting their most complex and demanding requirements. Our team stands ready to work closely with customers to ensure their project is completed to their satisfaction.</div>
                <div className="row industry-row sectionPadding">
                    <Industry name="medical" />
                    <Industry name="aerospace" />
                    <Industry name="defense" />
                    <Industry name="semiconductor" />
                    <Industry name="biotechnology" />
                    <Industry name="energy" />
                    <Industry name="communications" />
                    <Industry name="reasearch-and-science" />
                </div>
            </div>
        </>
    )
}
