import React, { Component } from 'react'
import Nav from '../components/navigation/Nav'
import HeaderImage from '../components/header/HeaderImage'
import SectionHeaderImage from '../components/header/SectionHeaderImage'
import Industries from '../components/industries/Industries'
import AboutUsContent from '../components/about-us/AboutUsContent'
import IsoCertificationContent from '../components/iso-certification/IsoCertificationContent'
import FacilitiesContent from '../components/facilities/FacilitiesContent'
import ContactUsContent from '../components/contact/ContactUsContent'
import FooterContent from '../components/footer/FooterContent'

export default class Home extends Component {
    render() {
            return (
            <main className="wrapper">
                <Nav />
                <HeaderImage />
                <section className="section section-industries">
                    <Industries />
                </section>
                <section className="section section-about-us">
                    <SectionHeaderImage sectionName="about-us"/>
                    <AboutUsContent />
                </section>
                <section className="section section-iso-certification">
                    <SectionHeaderImage sectionName="iso-certification"/>
                    <IsoCertificationContent />
                </section>
                <section className="section section-facilities">
                    <SectionHeaderImage sectionName="facilities"/>
                    <FacilitiesContent />
                </section>
                <section className="section section-contact-us">
                    <SectionHeaderImage sectionName="contact"/>
                    <ContactUsContent />
                </section>
                <section className="section section-footer">
                    <FooterContent />
                </section>
            </main>
        )
    }
}
