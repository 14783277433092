import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'

export default function FooterAddress() {
    return (
        <div className="footerContactAddressText">
            <b>Darko Precision, Inc.</b><br/><br/>470 Gianni Street<br/>Santa Clara, CA 95054<br/><br/><FontAwesomeIcon icon={faPhone} /> 408.988.6133<br/><FontAwesomeIcon icon={faEnvelope} /> info@dp-inc.com
        </div>
    )
}
