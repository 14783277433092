export const headerImgData = {
    "about-us" : {
        "title": "About us",
        "imgSrc": "./images/about/about-us-header-image-xl.jpg",
        "id": "aboutHeaderImage"
    },
    "iso-certification" : {
        "title": "ISO certification",
        "imgSrc": "./images/iso/ISO-header-image-xl.jpg",
        "id": "isoHeaderImage"
    },
    "facilities" : {
        "title": "Facilities",
        "imgSrc": "./images/facilities/facilities-header-image-xl.jpg",
        "id": "facilitiesHeaderImage"
    },
    "contact" : {
        "title": "Contact us",
        "imgSrc": "./images/contact/contact-us-header-image-xl.jpg",
        "id": "contactHeaderImage"
    }
}