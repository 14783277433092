import React, { Component } from 'react'

export default class TermsAndConditions extends Component {
    render() {
            return (
            <main className="wrapper">
                <embed src="/QSP-PU-01_Purchase_Order_Work_Instruction_Rev07.pdf" type="application/pdf" height="100%" width="100%" />
            </main>
        )
    }
}
