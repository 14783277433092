import React from 'react'
import Logo from '../navigation/Logo'
import DarkoAddress from './DarkoAddress'

export default function ContactUsAddress() {
    return (
        <div className="col-md-12 clear-fix">
            <Logo />
            <DarkoAddress />
        </div>
    )
}
