import React from 'react'
import {isoData} from './IsoCertificationData'

export default function IsoCertificationContent() {
    return (
        <div className="container padding-top-120 sectionPadding clear-fix">
            <div className="row">
                <div className="col-md-4 order-2 order-md-1">
                    <div className="row">
                        <div className="col-3 col-md-5 isoImages">
                            <img src={isoData.image1.src} width="100%" height="auto" alt="iso cert" />
                        </div>
                        <div className="col-3 col-md-5 isoImages">
                            <img src={isoData.image2.src} width="100%" height="auto" alt="iso cert" />
                        </div>
                        <div className="col-6 col-md-12 pl-0 isoImages">
                            <img src={isoData.image3.src} width="100%" height="auto" alt="iso cert" />
                        </div>
                    </div>
                </div>  
                <div className="col-md-8 order-1 order-md-2">
                    <div className="regular-text">
                        At Darko Precision, Inc., we design, engineer, machine, and manufacture high-tech products and make sure we not only meet–but exceed– our client’s precise and demanding requirements.<br/><br/>As such, we dedicate our time and talent to continuously improving company-wide, from measuring the effectiveness of our quality management system to providing the best training and assistance for our passionate DPI team.<br/><br/>At this moment, we have the following certification; <b>ISO9001:2015</b> for General Manufacturing and improvement, <b>AS9100 Rev.D</b> for manufacturing of Aerospace products and <b>ISO13485:2016</b> for Medical Devices. We are also in the process of training personnel and implementing Lean Six Sigma projects to further our commitment to the excellence, efficiency and quality of our processes and products.
                    </div>
                </div>  
            </div>
        </div>
    )
}
